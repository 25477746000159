import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Import images
import benefitsImage from "../images/benefits.jpg";
import workImage from "../images/work.jpg";
import healthImage from "../images/health.jpg";

function FormPage() {
  const { form_id } = useParams(); // Get form_id from URL parameters
  const [formInfo, setFormInfo] = useState(null); // State to hold form information
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error

  // Function to remove HTML tags
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  useEffect(() => {
    // Fetch form data using form_id from the backend
    fetch(`${process.env.REACT_APP_API_URL}/forms/${form_id}`) // Adjust the endpoint as per your Flask API
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch form data");
        }
        return response.json();
      })
      .then((data) => {
        setFormInfo(data); // Set the fetched data to state
        setLoading(false); // Set loading to false after fetching
      })
      .catch((error) => {
        console.error("Error fetching form:", error);
        setError(error.message); // Set error message if the fetch fails
        setLoading(false); // Set loading to false if there's an error
      });
  }, [form_id]);

  // Determine the image based on va_administration
  const getImageForAdministration = () => {
    switch (formInfo?.va_administration) {
      case "VBA":
        return benefitsImage;
      case "VACO":
        return workImage;
      case "VHA":
        return healthImage;
      default:
        return null; // No image for other values
    }
  };

  // Handle loading state
  if (loading) {
    return <p>Loading form data...</p>;
  }

  // Handle error state
  if (error) {
    return <p>Error: {error}</p>;
  }

  // If formInfo is null, handle it accordingly
  if (!formInfo) {
    return <p>No form information found.</p>;
  }

  return (
    <div
      className="container mt-4"
      style={{
        display: "flex",
        gap: "40px",
        alignItems: "flex-start",
        justifyContent: "center",
        minHeight: "10vh",
        padding: "40px 20px",
      }}
    >
      {/* Left Section */}
      <div
        style={{
          flex: 1,
          maxWidth: "50%",
        }}
      >
        <h1 style={{ fontSize: "2rem", fontWeight: "bold", marginBottom: "20px" }}>
          {formInfo.form_name}
        </h1>
        <h6 style={{ color: "gray", fontSize: "1.2rem", marginBottom: "20px" }}>
          {formInfo.form_type}
        </h6>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>VA Administration:</strong> {formInfo.va_administration}
        </p>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Form Number:</strong> {formInfo.form_number}
        </p>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Form URL:</strong>{" "}
          <a
            href={formInfo.form_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#0056b3", textDecoration: "underline" }}
          >
            {formInfo.form_url}
          </a>
        </p>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Usage Description:</strong> {stripHtmlTags(formInfo.usage_description)}
        </p>
        <p style={{ fontSize: "1.1rem" }}>
          <strong>Looking for more details?</strong>{" "}
          {formInfo.form_details_url ? (
            <a
              href={formInfo.form_details_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#0056b3", textDecoration: "underline" }}
            >
              Click this link!
            </a>
          ) : (
            "No additional details available."
          )}
        </p>
      </div>

      {/* Right Section (Dynamic Image) */}
      <div
        style={{
          flex: 1,
          maxWidth: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {getImageForAdministration() ? (
          <img
            src={getImageForAdministration()}
            alt={`For ${formInfo.va_administration}`}
            style={{
              maxWidth: "100%",
              height: "auto",
              borderRadius: "10px",
              objectFit: "contain",
            }}
          />
        ) : (
          <p style={{ textAlign: "center", color: "gray", fontSize: "1.5rem" }}>
            No image available
          </p>
        )}
      </div>
    </div>
  );
}

export default FormPage;

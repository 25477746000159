import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, Marker } from "@react-google-maps/api";

function FacilityPage() {
  const { facility_id } = useParams();
  const [facilityInfo, setFacilityInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [coordinates, setCoordinates] = useState(null);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/facilities/${facility_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch facility data");
        }
        return response.json();
      })
      .then((data) => {
        setFacilityInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching facility:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [facility_id]);

  useEffect(() => {
    if (facilityInfo?.address) {
      geocodeAddress(facilityInfo.address);
    }
  }, [facilityInfo]);

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0].geometry.location;
        setCoordinates({ lat: location.lat(), lng: location.lng() });
      } else {
        console.error("Geocoding failed:", status);
      }
    });
  };

  if (loading) {
    return <p>Loading facility data...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!facilityInfo) {
    return <p>No facility information found.</p>;
  }

  return (
    <div
      className="container"
      style={{
        display: "flex",
        gap: "40px",
        alignItems: "flex-start",
        justifyContent: "center",
        minHeight: "50vh",
        padding: "80px 20px",
        boxSizing: "border-box",
        overflow: "hidden", // Remove scrolling
      }}
    >
      {/* Left Section */}
      <div style={{ flex: 1 }}>
        <h1 style={{ fontSize: "2rem", fontWeight: "bold", marginBottom: "20px" }}>
          {facilityInfo.facility_name}
        </h1>
        <h6 style={{ color: "gray", fontSize: "1.2rem", marginBottom: "20px" }}>
          {facilityInfo.facility_type}
        </h6>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Website:</strong>{" "}
          <a
            href={facilityInfo.website}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#0056b3", textDecoration: "underline" }}
          >
            {facilityInfo.website}
          </a>
        </p>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Address:</strong> {facilityInfo.address}
        </p>
        <p style={{ fontSize: "1.1rem" }}>
          <strong>Phone Number:</strong> {facilityInfo.phone_number}
        </p>
      </div>

      {/* Right Section (Google Maps) */}
      <div style={{ flex: 1 }}>
        {coordinates ? (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={coordinates}
            zoom={14}
          >
            <Marker position={coordinates} />
          </GoogleMap>
        ) : (
          <p
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "1.2rem",
              color: "gray",
            }}
          >
            Loading map...
          </p>
        )}
      </div>
    </div>
  );
}

export default FacilityPage;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css'

const jobsPerPage = 20;

function VeteranJobs() {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to track the search term
  const [sortConfig, setSortConfig] = useState({ field: null, order: null }); // State for sorting

  // Fetch job data from the Flask backend API whenever searchTerm changes
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/employers?search=${searchTerm}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch job data');
        }
        return response.json();
      })
      .then(data => {
        setJobs(data);
      })
      .catch(error => {
        console.error('Error fetching jobs:', error);
        setError(error.message);
      });
  }, [searchTerm]);

  const totalPages = Math.ceil(jobs.length / jobsPerPage);
  const startIndex = (currentPage - 1) * jobsPerPage;

  // Sorting logic
  const sortedJobs = [...jobs];
  if (sortConfig.field && sortConfig.order) {
    sortedJobs.sort((a, b) => {
      const fieldA = a[sortConfig.field];
      const fieldB = b[sortConfig.field];
      const comparison =
        typeof fieldA === 'string' ? fieldA.localeCompare(fieldB) : fieldA - fieldB;
      return sortConfig.order === 'asc' ? comparison : -comparison;
    });
  }

  const currentJobs = sortedJobs.slice(startIndex, startIndex + jobsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page whenever the search term changes
  };

  const toggleSortOrder = (field) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.field === field) {
        // Cycle through: ascending -> descending -> no sort
        const nextOrder = prevConfig.order === 'asc' ? 'desc' : prevConfig.order === 'desc' ? null : 'asc';
        return { field: nextOrder ? field : null, order: nextOrder };
      }
      // Default to ascending for new fields
      return { field, order: 'asc' };
    });
    setCurrentPage(1); // Reset to the first page when sorting
  };

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container text-center" style={{ marginTop: "20px" }}>
      <h1>Veteran Jobs</h1>
      <p>
        Veteran jobs are offered by preferred employers who value the skills, discipline, and experience veterans bring to the workforce.
        These employers provide opportunities in various fields, supporting the transition from military to civilian careers.
        With a commitment to hiring veterans, they help ensure veterans continue to succeed and thrive in their post-service careers.
      </p>

      {/* Search bar */}
      <div className="d-flex justify-content-end mb-4">
        <input
          type="text"
          placeholder="Search jobs..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="small-search-bar"
        />
      </div>
      

      {jobs.length === 0 ? (
        <p>No jobs found.</p>
      ) : (
        <>
          <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => toggleSortOrder('job_name')} className="sortable">
                Job Title
                {sortConfig.field === 'job_name'
                  ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼')
                  : ' ⇅'}
              </th>
              <th onClick={() => toggleSortOrder('company_name')} className="sortable">
                Company
                {sortConfig.field === 'company_name'
                  ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼')
                  : ' ⇅'}
              </th>
              <th onClick={() => toggleSortOrder('location')} className="sortable">
                Location
                {sortConfig.field === 'location'
                  ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼')
                  : ' ⇅'}
              </th>
              <th onClick={() => toggleSortOrder('pay_min')} className="sortable">
                Min Pay
                {sortConfig.field === 'pay_min'
                  ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼')
                  : ' ⇅'}
              </th>
              <th onClick={() => toggleSortOrder('pay_max')} className="sortable">
                Max Pay
                {sortConfig.field === 'pay_max'
                  ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼')
                  : ' ⇅'}
              </th>
              <th>Link</th>
            </tr>
          </thead>
            <tbody>
              {currentJobs.map((job, index) => (
                <tr key={Number(job.job_id)}>
                  <td>{startIndex + index + 1}</td>
                  <td>{job.job_name}</td>
                  <td>{job.company_name}</td>
                  <td>{job.location}</td>
                  <td>{job.pay_min}</td>
                  <td>{job.pay_max}</td>
                  <td>
                    <Link to={`/VeteranJobs/${Number(job.job_id)}`}>View Job</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex justify-content-between align-items-center">
            <p>
              Showing {startIndex + 1} to {startIndex + currentJobs.length} of {jobs.length} jobs
            </p>
            <div>
              <button
                onClick={handlePreviousPage}
                className="btn btn-primary"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="mx-2">Page {currentPage} of {totalPages}</span>
              <button
                onClick={handleNextPage}
                className="btn btn-primary"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default VeteranJobs;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const formsPerPage = 20;

function VeteranForms() {
  const [forms, setForms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState(""); // Dropdown selection
  const [sortConfig, setSortConfig] = useState({ field: null, order: null });

  // Fetch form data from the backend API
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/forms?search=${searchTerm}&admins=${selectedAdmin}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch forms data");
        }
        return response.json();
      })
      .then((data) => {
        setForms(data);
      })
      .catch((error) => {
        console.error("Error fetching forms:", error);
      });
  }, [searchTerm, selectedAdmin]);

  const totalPages = Math.ceil(forms.length / formsPerPage);
  const startIndex = (currentPage - 1) * formsPerPage;

  // Sorting logic
  const sortedForms = [...forms];
  if (sortConfig.field && sortConfig.order) {
    sortedForms.sort((a, b) => {
      const fieldA = a[sortConfig.field];
      const fieldB = b[sortConfig.field];
      const comparison =
        typeof fieldA === "string" ? fieldA.localeCompare(fieldB) : fieldA - fieldB;
      return sortConfig.order === "asc" ? comparison : -comparison;
    });
  }

  const currentForms = sortedForms.slice(startIndex, startIndex + formsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleDropdownChange = (e) => {
    setSelectedAdmin(e.target.value);
    setCurrentPage(1);
  };

  const toggleSortOrder = (field) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.field === field) {
        const nextOrder =
          prevConfig.order === "asc" ? "desc" : prevConfig.order === "desc" ? null : "asc";
        return { field: nextOrder ? field : null, order: nextOrder };
      }
      return { field, order: "asc" };
    });
    setCurrentPage(1);
  };

  return (
    <div className="container text-center" style={{ marginTop: "20px" }}>
      <h1>Veteran Forms</h1>
      <p>
        Veteran forms are essential documents required for veterans and their families to apply
        for various benefits and services.
      </p>

      <div className="d-flex justify-content-between align-items-start mb-4">
        {/* Dropdown Filter */}
        <div className="d-flex flex-row align-items-center">
          <label htmlFor="admin-dropdown" className="mb-2" style={{ marginRight: "10px", whiteSpace: "nowrap" }} >Admin Type:</label>
          <select
            id="admin-dropdown"
            value={selectedAdmin}
            onChange={handleDropdownChange}
            className="form-select"
            style={{ maxWidth: "200px" }}
          >
            <option value="">All</option>
            <option value="VHA">VHA</option>
            <option value="VBA">VBA</option>
            <option value="VACO">VACO</option>
          </select>
        </div>

        {/* Search Bar */}
        <div className="d-flex align-items-end" style={{ height: "100%" }}>
          <input
            type="text"
            placeholder="Search forms..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="small-search-bar"
            style={{ height: "36px" }}
          />
        </div>
      </div>

      {forms.length === 0 ? (
        <p>No forms found.</p>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => toggleSortOrder("form_name")} className="sortable">
                  Form Name
                  {sortConfig.field === "form_name"
                    ? sortConfig.order === "asc"
                      ? " ▲"
                      : " ▼"
                    : " ⇅"}
                </th>
                <th onClick={() => toggleSortOrder("form_number")} className="sortable">
                  Number
                  {sortConfig.field === "form_number"
                    ? sortConfig.order === "asc"
                      ? " ▲"
                      : " ▼"
                    : " ⇅"}
                </th>
                <th onClick={() => toggleSortOrder("form_type")} className="sortable">
                  Type
                  {sortConfig.field === "form_type"
                    ? sortConfig.order === "asc"
                      ? " ▲"
                      : " ▼"
                    : " ⇅"}
                </th>
                <th onClick={() => toggleSortOrder("va_administration")} className="sortable">
                  Administration
                  {sortConfig.field === "va_administration"
                    ? sortConfig.order === "asc"
                      ? " ▲"
                      : " ▼"
                    : " ⇅"}
                </th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {currentForms.map((form, index) => (
                <tr key={form.form_id}>
                  <td>{startIndex + index + 1}</td>
                  <td>{form.form_name}</td>
                  <td>{form.form_number}</td>
                  <td>{form.form_type}</td>
                  <td>{form.va_administration}</td>
                  <td>
                    <Link to={`/VeteranForms/${form.form_id}`}>View Form</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex justify-content-between align-items-center">
            <p>
              Showing {startIndex + 1} to {startIndex + currentForms.length} of {forms.length} forms
            </p>
            <div>
              <button
                onClick={handlePreviousPage}
                className="btn btn-primary"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="mx-2">Page {currentPage} of {totalPages}</span>
              <button
                onClick={handleNextPage}
                className="btn btn-primary"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default VeteranForms;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LineChart, CartesianGrid, YAxis, Line, XAxis, Tooltip, ResponsiveContainer } from "recharts";

function JobPage() {
  const { job_id } = useParams(); // Use job_id from the URL params
  const [jobInfo, setJobInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch job data using job_id from the backend
    fetch(`${process.env.REACT_APP_API_URL}/employers/${job_id}`) // Adjust the endpoint as per your Flask API
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch job data");
        }
        return response.json();
      })
      .then((data) => {
        setJobInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [job_id]);

  if (loading) {
    return <p>Loading job data...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!jobInfo) {
    return <p>No job information found.</p>;
  }

  // Data for the number line visualization
  const payData = [
    { name: "Min Pay", value: jobInfo.pay_min },
    { name: "Max Pay", value: jobInfo.pay_max },
  ];

  return (
    <div 
      className="container mt-4" 
      style={{ 
        display: "flex",
        gap: "40px",
        alignItems: "flex-start",
        justifyContent: "center",
        minHeight: "10vh",
        padding: "70px 20px",
      }}
    >
      {/* Left Section */}
      <div style={{ flex: 1, marginTop: "20px" }}>
        <h1 style={{ fontSize: "2rem", fontWeight: "bold", marginBottom: "20px" }}>
          {jobInfo.job_name}
        </h1>
        <h6 style={{ color: "gray", fontSize: "1.2rem", marginBottom: "20px" }}>
          {jobInfo.company_name}
        </h6>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Location:</strong> {jobInfo.location}
        </p>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Pay:</strong> ${jobInfo.pay_min} - ${jobInfo.pay_max}/year
        </p>
        <p style={{ fontSize: "1.1rem", marginBottom: "10px" }}>
          <strong>Pay Description:</strong> {jobInfo.pay_description}
        </p>
        <a
          href={jobInfo.job_url}
          className="btn btn-primary"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginTop: "10px" }}
        >
          Apply Now
        </a>
      </div>

      {/* Right Section (Recharts Number Line) */}
      <div style={{ flex: 1 }}>
        <div
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "#f9f9f9",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: "20px",
          }}
        >
          <h5 style={{ textAlign: "center", marginBottom: "20px" }}>
            Pay Range Visualization
          </h5>
          <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={payData}
            margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
            </XAxis>
            <YAxis
            />
            <Tooltip formatter={(value) => `$${value}`} />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              strokeWidth={2}
              dot={{ r: 6 }}
            />
          </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default JobPage;

import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const SearchResults = () => {
  const location = useLocation();
  const [results, setResults] = useState({ jobs: [], facilities: [], forms: [] });
  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    const fetchResults = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/search?search=${query}`);
      // const response = await fetch(`http://localhost:5001/search?search=${query}`);
      const data = await response.json();
    //   console.log("API Response:", data); 
      setResults(data);
    };

    if (query) fetchResults();
  }, [query]);

  return (
    <div>
      <h1>Search Results for "{query}"</h1>
      <div>
        <h2>Jobs</h2>
        <ul>
          {results.jobs.map((job) => (
            <li key={job.job_id}>
              <Link to={`/VeteranJobs/${job.job_id}`}>{job.job_name}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Facilities</h2>
        <ul>
          {results.facilities.map((facility) => (
            <li key={facility.facility_id}>
              <Link to={`/VeteranFacilities/${facility.facility_id}`}>{facility.facility_name}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Forms</h2>
        <ul>
          {results.forms.map((form) => (
            <li key={form.form_id}>
              <Link to={`/VeteranForms/${form.form_id}`}>{form.form_name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchResults;

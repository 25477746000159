import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchTerm.trim()) {
        navigate(`/search?query=${searchTerm}`);
      }
    }
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{
        backgroundColor: '#181818',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        padding: '10px 20px',
      }}
    >
      <div className="container-fluid">
        {/* Brand */}
        <Link
          className="navbar-brand"
          to="/"
          style={{
            fontWeight: 'bold',
            fontSize: '1.5rem',
            color: 'white',
            textDecoration: 'none',
          }}
        >
          VetAssist
        </Link>

        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Links */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link className="nav-link text-white" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/VeteranJobs">
                Veteran Jobs
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/VeteranFacilities">
                Veteran Facilities
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/VeteranForms">
                Veteran Forms
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/AboutPage">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/VisualizationPage">
                Visualizations
              </Link>
            </li>
          </ul>

          {/* Search Bar */}
          <div className="d-flex">
            <input
              className="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                maxWidth: '400px',
                borderRadius: '20px',
                border: '1px solid #ccc',
              }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

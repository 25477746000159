import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const facilitiesPerPage = 20;

function VeteranFacilities() {
  const [facilities, setFacilities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // Track search term
  const [selectedFacilityType, setSelectedFacilityType] = useState(""); // Track dropdown selection
  const [sortConfig, setSortConfig] = useState({ field: null, order: null }); // Track sorting

  // Fetch facility data from the backend API
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/facilities?search=${searchTerm}&facilityTypes=${selectedFacilityType}`
    )
      .then(response => response.json())
      .then(data => {
        setFacilities(data);
      })
      .catch(error => {
        console.error('Error fetching facilities:', error);
      });
  }, [searchTerm, selectedFacilityType]);

  const totalPages = Math.ceil(facilities.length / facilitiesPerPage);
  const startIndex = (currentPage - 1) * facilitiesPerPage;

  // Sorting logic
  const sortedFacilities = [...facilities];
  if (sortConfig.field && sortConfig.order) {
    sortedFacilities.sort((a, b) => {
      const fieldA = a[sortConfig.field];
      const fieldB = b[sortConfig.field];
      const comparison =
        typeof fieldA === 'string' ? fieldA.localeCompare(fieldB) : fieldA - fieldB;
      return sortConfig.order === 'asc' ? comparison : -comparison;
    });
  }

  const currentFacilities = sortedFacilities.slice(startIndex, startIndex + facilitiesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleDropdownChange = (e) => {
    setSelectedFacilityType(e.target.value);
    setCurrentPage(1); // Reset to the first page on filter change
  };

  const toggleSortOrder = (field) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.field === field) {
        const nextOrder =
          prevConfig.order === 'asc' ? 'desc' : prevConfig.order === 'desc' ? null : 'asc';
        return { field: nextOrder ? field : null, order: nextOrder };
      }
      return { field, order: 'asc' };
    });
    setCurrentPage(1); // Reset to the first page when sorting
  };

  return (
    <div className="container text-center" style={{ marginTop: "20px" }}>
      <h1>Veteran Facilities</h1>
      <p>
        Veteran facilities, including VA health care centers, cemeteries, and benefit offices,
        provide critical services and support to veterans and their families. These facilities
        offer medical care, mental health services, burial honors, and assistance with benefits
        and claims. They are designed to ensure veterans receive the care and recognition they
        deserve for their service.
      </p>

      <div className="d-flex justify-content-between align-items-start mb-4">
        {/* Dropdown Filter */}
        <div className="d-flex flex-row align-items-center">
          <label htmlFor="facility-type-dropdown" className="mb-" style={{ marginRight: "10px", whiteSpace: "nowrap" }}>Facility Type:</label>
          <select
            id="facility-type-dropdown"
            value={selectedFacilityType}
            onChange={handleDropdownChange}
            className="form-select"
            style={{ maxWidth: "200px", height: "36px" }}
          >
            <option value="">All</option>
            <option value="va_benefits_facility">VA Benefits</option>
            <option value="va_health_facility">VA Health</option>
            <option value="vet_center">Vet Center</option>
            <option value="va_cemetery">VA Cemetery</option>
          </select>
        </div>

        {/* Search Bar */}
        <div className="d-flex align-items-end" style={{ height: "100%" }}>
          <input 
            type="text" 
            placeholder="Search facilities..." 
            value={searchTerm} 
            onChange={handleSearchChange} 
            className="small-search-bar" 
            style={{ height: "36px" }}  
          />
        </div>
      </div>

      {facilities.length === 0 ? (
        <p>No facilities found.</p>
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => toggleSortOrder('facility_name')} className="sortable">
                  Facility Name
                  {sortConfig.field === 'facility_name' 
                    ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼') 
                    : ' ⇅'}
                </th>
                <th onClick={() => toggleSortOrder('facility_type')} className="sortable">
                  Facility Type
                  {sortConfig.field === 'facility_type' 
                    ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼') 
                    : ' ⇅'}
                </th>
                <th onClick={() => toggleSortOrder('address')} className="sortable">
                  Address
                  {sortConfig.field === 'address' 
                    ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼') 
                    : ' ⇅'}
                </th>
                <th onClick={() => toggleSortOrder('phone_number')} className="sortable">
                  Phone Number
                  {sortConfig.field === 'phone_number' 
                    ? (sortConfig.order === 'asc' ? ' ▲' : ' ▼') 
                    : ' ⇅'}
                </th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {currentFacilities.map((facility, index) => (
                <tr key={facility.facility_id}>
                  <td>{startIndex + index + 1}</td>
                  <td>{facility.facility_name}</td>
                  <td>{facility.facility_type}</td>
                  <td>{facility.address}</td>
                  <td>{facility.phone_number}</td>
                  <td>
                    <Link to={`/VeteranFacilities/${facility.facility_id}`}>View Facility</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex justify-content-between align-items-center">
            <p>
              Showing {startIndex + 1} to {startIndex + currentFacilities.length} of {facilities.length} facilities
            </p>
            <div>
              <button 
                onClick={handlePreviousPage} 
                className="btn btn-primary" 
                disabled={currentPage === 1}>
                Previous
              </button>
              <span className="mx-2">Page {currentPage} of {totalPages}</span>
              <button 
                onClick={handleNextPage} 
                className="btn btn-primary" 
                disabled={currentPage === totalPages}>
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default VeteranFacilities;


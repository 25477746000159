import React, { useEffect, useState } from 'react';
import { Scatter, ScatterChart, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import "./VisualizationPage.css";

const COLORS = ['#FF9999', '#82ca9d', '#DDA0DD', '#ADD8E6', '#FF8042'];

function VisualizationPage() {
  const [jobsData, setJobsData] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [formsData, setFormsData] = useState([]);
  const [housingData, setHousingData] = useState([]);
  const [scholarshipData, setScholarshipData] = useState([]);
  const [universityData, setUniversityData] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#FFFFFF', padding: '10px', border: '1px solid #ccc' }}>
          <p style={{ margin: 0, color: 'black', fontWeight: 'bold' }}>{label}</p>
          <p style={{ margin: 0, color: 'black' }}>count: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };

  // Fetch data for new visualizations
  useEffect(() => {
    const fetchVisualizationData = async () => {
      try {
        const housingResponse = await fetch('/devData/housing.json');

        const housing = await housingResponse.json();

        setHousingData(housing.housing);
      } catch (error) {
        console.error('Error fetching visualization data:', error);
      }
    };

    fetchVisualizationData();
  }, []);


  useEffect(() => {
    fetch('/devData/universities.json')
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.universities
          .map((university) => ({
            name: university.name,
            population: university.student_size || 0,
          }))
          .sort((a, b) => b.population - a.population) // Sort by population descending
          .slice(0, 10); // Select top 10 universities
        setUniversityData(sortedData);
      })
      .catch((error) => console.error('Error fetching universities:', error));
  }, []);


useEffect(() => {
  fetch('/devData/scholarships.json')
    .then((response) => response.json())
    .then((data) => {
      const groupedByRange = data.scholarships.reduce((acc, scholarship) => {
        const amount = parseFloat(scholarship.amount.replace(/[$,]/g, '')) || 0;

        // Define ranges
        let range = '';
        if (amount <= 1000) range = '$0 - $1,000';
        else if (amount <= 5000) range = '$1,001 - $5,000';
        else if (amount <= 10000) range = '$5,001 - $10,000';
        else range = '$10,001+';

        if (!acc[range]) acc[range] = 0;
        acc[range]++;

        return acc;
      }, {});

      const formattedData = Object.entries(groupedByRange).map(([name, value]) => ({
        name,
        value,
      }));
      setScholarshipData(formattedData);
    })
    .catch((error) => console.error('Error fetching scholarships:', error));
}, []);

  // Fetch jobs data
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/employers`)
      .then(response => response.json())
      .then(data => {
        const scatterData = data.map(job => ({
          company: job.company_name || "Unknown",
          averagePay: (job.pay_min + job.pay_max) / 2 || 0,
        }));
        setJobsData(scatterData);
      })
      .catch(error => console.error('Error fetching jobs:', error));
  }, []);

  // Fetch facilities data
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/facilities`)
      .then(response => response.json())
      .then(data => {
        const groupedData = data.reduce((acc, facility) => {
          const type = facility.facility_type || "Unknown";
          if (!acc[type]) {
            acc[type] = 0;
          }
          acc[type]++;
          return acc;
        }, {});
        const formattedData = Object.entries(groupedData).map(([name, value]) => ({ name, value }));
        setFacilitiesData(formattedData);
      })
      .catch(error => console.error('Error fetching facilities:', error));
  }, []);

  // Fetch forms data
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/forms`)
      .then(response => response.json())
      .then(data => {
        const groupedData = data.reduce((acc, form) => {
          const admin = form.va_administration || "Unknown";
          if (!acc[admin]) {
            acc[admin] = 0;
          }
          acc[admin]++;
          return acc;
        }, {});
        const formattedData = Object.entries(groupedData).map(([name, count]) => ({ name, count }));
        setFormsData(formattedData);
      })
      .catch(error => console.error('Error fetching forms:', error));
  }, []);


  // const COLORS = ['#FF9999', '#82ca9d', '#DDA0DD', '#ADD8E6', '#ADD8E6'];

  return (
    <div style = {{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
        {/* Left Column */}
        <div style={{ flex: 1, textAlign: 'center', margin: '40px' }}>
          <h2>Average Pay by Company</h2>
          <ResponsiveContainer width="100%" height={600}>
            <ScatterChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="category" dataKey="company" name="Company" />
              <YAxis type="number" dataKey="averagePay" name="Average Pay" />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
              <Scatter data={jobsData} fill="#8884d8" />
            </ScatterChart>
          </ResponsiveContainer>
        </div>

        {/* Middle Column */}
        <div style={{ flex: 1, textAlign: 'center', margin: '40px' }}>
          <h2>Distribution of Facilities by Type</h2>
          <ResponsiveContainer width="100%" height={600}>
            <PieChart>
              <Pie
                data={facilitiesData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius="80%"
                fill="#8884d8"
                label
              >
                {facilitiesData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Right Column */}
        <div style={{ flex: 1, textAlign: 'center', margin: '40px' }}>
          <h2>Number of Forms by VA Administration</h2>
          <ResponsiveContainer width="100%" height={600}>
            <BarChart data={formsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="count" fill="#FFDAB9" />
            </BarChart>
          </ResponsiveContainer>
        </div>

      </div>
      <div
        style={{
          textAlign: "left",
          marginTop: "20px",
          padding: "20px",
          width: "100%", // Ensures the section spans the entire width
        }}
      >
        <h1 style={{ fontSize: "30px", paddingBottom: "20px" }}>Self Critiques:</h1>
        <p style={{ fontSize: "24px" }}>What did we do well?</p>
        <p style={{ fontSize: "18px" }}>
          We did well on working well together and communicating. We did a great
          job of fully understanding what each person was doing and knowing our
          goals for each part of the project itself.
        </p>
        <p style={{ fontSize: "24px" }}>What did we learn?</p>
        <p style={{ fontSize: "18px" }}>
          We learned about what specifically veterans struggle with after their
          service and ways in which they can succeed through different tools and
          programs they can access such as education, jobs, and facilities.
        </p>
        <p style={{ fontSize: "24px" }}>What did we teach each other?</p>
        <p style={{ fontSize: "18px" }}>
          We taught each other different skills that we excelled at. Some of us
          were good at backend APIs such as Flask while others were good at
          frontend work such as React. We taught each other these different
          tools to succeed in the group.
        </p>
        <p style={{ fontSize: "24px" }}>What can we do better?</p>
        <p style={{ fontSize: "18px" }}>
          We can do better at staying more organized throughout the project as
          sometimes, we got caught off guard by the due date of the projects.
        </p>
        <p style={{ fontSize: "24px" }}>What effect did the peer reviews have?</p>
        <p style={{ fontSize: "18px" }}>
          They had a strong effect on making our website better for the user as
          a third-party perspective allowed us to understand where we needed
          work on different parts of our website.
        </p>
        <p style={{ fontSize: "24px" }}>What puzzles us?</p>
        <p style={{ fontSize: "18px" }}>
          What puzzles us is figuring out more ways to get these resources
          accessible to veterans and give them more aid for post-service.
        </p>
      </div>
      <div
        className="new-visualizations"
        style={{
          marginTop: "40px",
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* First Visualization */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h2>Average Housing Prices by Apartment</h2>
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="category" dataKey="apartment_name" name="Apartment" />
              <YAxis type="number" dataKey="lowest_price" name="Price" />
              <Tooltip />
              <Scatter data={housingData} fill="#8884d8" />
            </ScatterChart>
          </ResponsiveContainer>
        </div>

        {/* Second Visualization */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h2>Scholarship Amount Distribution</h2>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={scholarshipData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius="70%"
                fill="#8884d8"
                label={(entry) => `${entry.name}: ${entry.value}`}
              >
                {scholarshipData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${value} scholarships`} />
            </PieChart>
          </ResponsiveContainer>
        </div>


        {/* Third Visualization */}
        <div style={{ flex: 1, textAlign: "center" }}>
          <h2>Top 10 Universities by Population</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={universityData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={{ fontSize: 10 }} />
              <YAxis />
              <Tooltip formatter={(value) => `${value.toLocaleString()} students`} />
              <Bar dataKey="population" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          marginTop: "20px",
          padding: "20px",
          width: "100%", // Ensures the section spans the entire width
        }}
      >
        <h1 style={{ fontSize: "30px", paddingBottom: "20px" }}>Other Critiques:</h1>
        <p style={{ fontSize: "24px" }}>What did they do well?</p>
        <p style={{ fontSize: "18px" }}>
          We think their UI looks really nice. The home page especially looks great. 
          All of their buttons and fonts work well together.
        </p>
        <p style={{ fontSize: "24px" }}>How effective was their RESTful API?</p>
        <p style={{ fontSize: "18px" }}>
          We haven't used their RESTful API because it was not up. Instead, they provided us JSONs.
        </p>
        <p style={{ fontSize: "24px" }}>How well did they implement your user stories?</p>
        <p style={{ fontSize: "18px" }}>
          They did a great job. We provided them a lot of frontend stories and they made great progress through them.
        </p>
        <p style={{ fontSize: "24px" }}>What did we learn from their website?</p>
        <p style={{ fontSize: "18px" }}>
          We learned a lot about the different universities, scholarships, and housing throughout Texas.
          It was really surprising to us how many different scholarships there are.
        </p>
        <p style={{ fontSize: "24px" }}>What can they do better?</p>
        <p style={{ fontSize: "18px" }}>
          Their cards on their "Universities", "Scholarships", and "Housing" pages can be inproved. They could try
          to improve the spacing and use more modern features like rounded corners that better fit their website aesthetic.
        </p>
        <p style={{ fontSize: "24px" }}>What puzzles us about their website?</p>
        <p style={{ fontSize: "18px" }}>
          After looking at the housing JSON, we are puzzled by how they managed to scrape the images for each apartment.
          Different houses have pictures from different sites and the URLs seem to be formatted differently. We are surprised
          that they managed to scrape all this data together.
        </p>
      </div>
    </div>
  );
}

export default VisualizationPage;

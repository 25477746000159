import React, { useState, useEffect } from "react";
import "./HomePage.css";
import { BsBuilding } from "react-icons/bs";
import { IoNewspaper } from "react-icons/io5";
import { GrUserWorker } from "react-icons/gr";

function HomePage() {
  const [links, setLinks] = useState([]);

  const logoArr = [
    <BsBuilding size="80px" color="white" />,
    <IoNewspaper size="80px" color="white" />,
    <GrUserWorker size="80px" color="white" />,
  ];

  useEffect(() => {
    const facilityLinks = [
      { name: "Explore VA Facilities", url: "/VeteranFacilities" },
      { name: "View VA Forms", url: "/VeteranForms" },
      { name: "Find Jobs", url: "/VeteranJobs" },
    ];
    setLinks(facilityLinks);
  }, []);

  return (
    <div className="homepage-container">
      <h1 className="homepage-title">Welcome To VetAssist</h1>
      <h2 className="what-we-do">What We Do</h2>
      <p className="homepage-description">
        Veterans in Texas are currently facing numerous challenges, including
        access to <b>healthcare</b>, <b>education</b>, and <b>employment</b>{" "}
        opportunities. Our mission is to provide resources and support to help
        veterans overcome these obstacles and improve their quality of life.
      </p>
      <p className="homepage-statistic">
        According to recent statistics, approximately <b>25%</b> of veterans
        are unemployed compared to <b>21%</b> for non-veterans. This highlights
        the urgent need for veteran job aid and resources for our veterans.
      </p>

      <div className="link-box">
        {links.map((link, index) => (
          <a key={index} href={link.url} className="link-button">
            <div className="link-icon">{logoArr[index]}</div>
            <span>{link.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
